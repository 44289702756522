import { Award } from '../../../../types/types';
import { TableAward } from '../../../../components/TableAward';

interface iProps {
	award: Award | null;
	prizeAmt: any;
	tournamentStatus: number;
	registeredEnrolls: any;
}

export const Awards = ({
	award,
	tournamentStatus,
	prizeAmt,
	registeredEnrolls,
}: iProps) => {
	return (
		<div className="z-0 bg-[#f4f4f4] p-2 rounded-lg">
			<div className="mb-4">
				<TableAward
					award={award!}
					tournamentStatus={tournamentStatus}
					prizeTotal={prizeAmt}
					registeredEnrolls={registeredEnrolls}
				/>
			</div>
		</div>
	);
};
