import { RiArrowLeftSLine } from '@remixicon/react';
import { useNavigate } from 'react-router-dom';
import { useNavigateStore } from '../../store/navigation';

type ButtonProps = {
	className?: string;
	classNameIcon?: string;
};

const ButtonBack = ({ className, classNameIcon }: ButtonProps) => {
	const navigate = useNavigate();
	const { goBackButton } = useNavigateStore();
	const navigateState = useNavigateStore((state: any) => state.navigateState);

	return (
		<button
			onClick={() => {
				if (navigateState) {
					goBackButton(true);
				}
				navigate(-1);
			}}
			className={className}
		>
			<RiArrowLeftSLine className={classNameIcon} />
		</button>
	);
};

export default ButtonBack;
