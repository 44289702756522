import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type Balancetore = {
	balance: string;
	balanceReal: string;
	balanceBonus: string;
	saveBalance: (balance: string) => void;
	saveBalanceReal: (balanceReal: string) => void;
	saveBalanceBonus: (balanceBonus: string) => void;
	clearBalance: () => void;

	hideBalance: boolean;
	showAndHideBalance: (hideBalance: boolean) => void;
};

export const useBalanceStore = create(
	persist<Balancetore>(
		(set) => ({
			balance: '0',
			balanceReal: '0',
			balanceBonus: '0',
			hideBalance: false,
			showAndHideBalance: (hideBalance) => set({ hideBalance }),
			saveBalance: (balance) => set({ balance }),
			saveBalanceReal: (balanceReal) => set({ balanceReal }),
			saveBalanceBonus: (balanceBonus) => set({ balanceBonus }),
			clearBalance: () =>
				set({ balance: '0', balanceBonus: '0', balanceReal: '0' }),
		}),
		{
			name: 'balance',
			storage: createJSONStorage(() => localStorage),
		}
	)
);
