import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import Toast from '../ui/toast';
import { CurrencyFormatter, showWalletAndBalance } from '../../helpers/utils';
import { FutProIcon } from '../Icons/FutProIcon';

interface TableLeagueProps {
	thead: any[];
	tbody: any[];
	tournamentStatus: any;
}

export const TableLeague = ({
	thead,
	tbody,
	tournamentStatus,
}: TableLeagueProps) => {
	const navigate = useNavigate();
	return (
		<table className="min-w-full table-auto border-collapse bg-white">
			<thead className="border-b border-[#bcc0c4]">
				<tr>
					{thead.map((item: any, index: number) => (
						<th
							key={index}
							className="py-2 max-w-48 text-center self-stretch text-[#73777c] text-[10px] font-medium font-bahnschrift leading-3 tracking-tight"
						>
							{item.title}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{tbody.map((item: any, index: number) => (
					<tr
						key={index}
						onClick={() => {
							if (tournamentStatus === 0 && !item.reqIsPlayer) {
								toast(
									<Toast variant="error">
										A consulta só é permitida após o
										fechamento do torneio.
									</Toast>
								);
								return;
							}
							if (item.enrollId) {
								return navigate(`/enroll/${item.enrollId}`);
							}
						}}
						className="h-8 even:bg-[#f5f5f6]"
					>
						<td className="p-1 text-center self-stretch text-black text-[12px] max-[330px]:text-[10px] font-medium font-bahnschrift leading-[10px]">
							{item.nick.toLowerCase()}
						</td>
						{Number(item.classification) > 0 ? (
							<td className="px-1 py-1">
								<div className="py-1 bg-primary rounded-full justify-center items-center">
									<div className="text-center text-white text-[10px] font-normal font-bahnschrift leading-[10px]">
										{item.classification}
									</div>
								</div>
							</td>
						) : (
							<td></td>
						)}

						{Number(item.winPoints) > 0 ? (
							<td className="px-2 py-1">
								<div className="py-1 bg-[#73777c] rounded-full justify-center items-center">
									<div className="text-center text-white text-[10px] font-normal font-bahnschrift leading-[10px]">
										{item.winPoints}
									</div>
								</div>
							</td>
						) : (
							<td className="px-2 py-1">
								{tournamentStatus !== 0 &&
								item.leagueHasWinPoints ? (
									<div className="py-1 bg-[#73777c] rounded-full justify-center items-center">
										<div className="text-center text-white text-[10px] font-normal font-bahnschrift leading-[10px]">
											0
										</div>
									</div>
								) : (
									<></>
								)}
							</td>
						)}

						{Number(item.award) > 0 ? (
							<td className="px-1 py-1 text-center">
								<div className="px-2 py-1 bg-primary rounded-full inline-flex items-center justify-center w-fit">
									<div className="flex items-center gap-1 justify-center text-center text-white text-[10px] font-normal font-bahnschrift leading-[10px]">
										{showWalletAndBalance() ? (
											'R$ '
										) : (
											<FutProIcon
												style="w-3.5 h-3.5"
												color="#ffffff"
											/>
										)}
										{CurrencyFormatter(Number(item.award))}
									</div>
								</div>
							</td>
						) : (
							<td></td>
						)}
					</tr>
				))}
			</tbody>
		</table>
	);
};
