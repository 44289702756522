import { useParams } from 'react-router-dom';
import { Layout } from '../../../components/layout';
import { useEffect, useState } from 'react';
import { sentryCapture } from '../../../helpers/utils';
import { SentryError } from '../../../types/enums';
import { useApi } from '../../../hooks/useApi';
import { Scoreboard, ScoreboardData } from '../../../types/types';
import { RiRestartLine } from '@remixicon/react';
import ButtonBack from '../../../components/ui/buttonBack';
import { TableScoreboardFeed } from '../../../components/TableScoreboardFeed';
import TabsButtonScoreBoard from '../../../components/TabsButtonScoreBoard';
import { useStream } from '../../../contexts/GetStream/StreamContext';
import Button from '../../../components/ui/button';
import { useNavigateStore } from '../../../store/navigation';

export const ScoreboardById = () => {
	const { id } = useParams();
	const api = useApi();
	const { clientGetStream } = useStream();

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<ScoreboardData | null>(null);
	const [scoreboard, setScoreboard] = useState<Scoreboard[]>([]);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const limit = page === 0 ? 20 : 100;
	const offset = page === 0 ? 0 : 20 + (page - 1) * 100;

	const { resetNavigate, goBackButton } = useNavigateStore();
	const navigateState = useNavigateStore((state: any) => state.navigateState);
	const cameFromBackButton = useNavigateStore(
		(state: any) => state.cameFromBackButton
	);

	useEffect(() => {
		if (cameFromBackButton && navigateState) {
			setData(navigateState.data);
			setScoreboard(navigateState.scoreboard);
			setPage(navigateState.page);
			setHasMore(navigateState.hasMore);
			setTimeout(() => {
				const goToElement = document.querySelector('ion-content');
				if (goToElement) {
					goToElement.scrollToPoint(
						0,
						navigateState.scrollPosition,
						0
					);
				} else {
					console.error('Elemento não encontrado');
				}
			}, 0);
			goBackButton(false);
			resetNavigate();
		} else {
			fetchScoreboard();
		}
	}, []);

	useEffect(() => {
		if (data) {
			findTokenScore();
		}
	}, [data]);

	const fetchScoreboard = async () => {
		if (!id) return;
		try {
			setLoading(true);

			const res = await api.getScoreboard(id, limit, offset);
			if (!res?.success) {
				throw new Error(res.message);
			}

			setData(res.data);
			setPage((prevPage) => prevPage + 1);
			setScoreboard((prevFeed: any) => [
				...prevFeed,
				...res.data.scoreboard,
			]);

			if (res.currentPage >= res.totalPages) {
				setHasMore(false);
				return;
			}
		} catch (error: any) {
			sentryCapture(error, 'fetchScoreboard', SentryError.Error);
		} finally {
			setLoading(false);
		}
	};

	const findTokenScore = async () => {
		const response = await api.getTokenFeed();
		if (!response?.success) {
			throw new Error(response.message);
		}
		const { token } = response;
		if (clientGetStream) {
			const scoreUpdate = clientGetStream.feed(
				'scoreboard',
				'system',
				token
			);
			scoreUpdate.subscribe(handleScoreUpdate);
		}
	};
	const handleScoreUpdate = async (dataGetStream: any) => {
		const update = JSON.parse(dataGetStream.new[0].object).update;
		const tournamentId = JSON.parse(
			dataGetStream.new[0].object
		).tournamentId;

		if (update && tournamentId === data?.content.tournament?.id) {
			console.log('Atualizou o scoreboard!');
			updateScoreboard();
		}
	};

	const updateScoreboard = async () => {
		if (!id) return;
		try {
			const res = await api.getScoreboard(id, 20, 0);
			if (!res?.success) {
				throw new Error(res.message);
			}
			setScoreboard(res.data.scoreboard);
			if (res.currentPage >= res.totalPages) {
				setHasMore(false);
				setPage(res.currentPage);
			} else {
				setHasMore(true);
				setPage(1);
			}
		} catch (error: any) {
			sentryCapture(error, 'fetchScoreboard', SentryError.Error);
		}
	};

	const handleLoadMore = async () => {
		if (!id) return;
		try {
			setIsLoading(true);

			const res = await api.getScoreboard(id, limit, offset);
			if (!res?.success) {
				throw new Error(res.message);
			}

			setScoreboard((prevFeed: any) => [
				...prevFeed,
				...res.data.scoreboard,
			]);
			setPage((prevPage) => prevPage + 1);
			if (res.currentPage >= res.totalPages) {
				setHasMore(false);
				return;
			}
		} catch (error: any) {
			sentryCapture(error, 'fetchScoreboard', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Layout>
			<div className="px-3 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between py-6 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
					<ButtonBack classNameIcon="" />
					<p className="text-xl font-semibold text-custom-gray1 font-bahnschrift">
						Classificação
					</p>
					<div></div>
				</div>
				{loading ? (
					<div className="flex items-center justify-center">
						<RiRestartLine className="mr-2 h-4 w-4 animate-spin" />
					</div>
				) : (
					<div className="flex flex-col gap-4">
						{data && scoreboard && (
							<>
								<div className="w-full rounded-lg gap-2 flex flex-col items-start">
									<div className="relative w-full h-48 lg:h-48 overflow-hidden rounded-xl bg-custom-gray10">
										{data.content.image ? (
											<picture>
												<source
													srcSet={data.content.image}
													media="(max-width: 767px)"
													className="lg:h-48 w-full h-full object-cover z-0"
												/>
												<source
													srcSet={data.content.image}
													media="(max-width: 768px)"
													className="lg:h-48 w-full h-full object-cover z-0"
												/>
												<source
													srcSet={
														data.content.imageWeb
													}
													media="(max-width: 1024px)"
													className="lg:h-48 w-full h-full object-cover z-0"
												/>
												<img
													className="lg:h-48 w-full h-full object-cover z-0"
													alt={data.content.title}
													src={data.content.imageWeb}
												/>
											</picture>
										) : (
											<div
												className={`absolute inset-0 w-full object-cover px-4 z-0 rounded-b-xl`}
												dangerouslySetInnerHTML={{
													__html: data.content.html,
												}}
											/>
										)}
										{data.content.image && (
											<div className="absolute inset-0 w-full h-full flex flex-col items-center mt-3 z-10">
												<div className="relative -mb-2 bg-primary rounded-3xl justify-center items-center gap-2.5 inline-flex">
													<div className="py-1 px-6 text-center text-white text-base font-bold font-bahnschrift leading-snug">
														{
															data.content
																.tournament
																?.title
														}
													</div>
												</div>
												<div className="w-full px-1 py-1.5 bg-neutral-70/10 rounded-lg justify-center items-center inline-flex">
													<div className="flex flex-col">
														<span className="text-white text-center font-bahnschrift">
															Confira a
															classificação deste
															torneio.
														</span>
														<span className="text-white text-center font-bahnschrift">
															{
																data.content
																	.tournament
																	?.reference
															}
														</span>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>

								<TableScoreboardFeed
									thead={[
										{
											title: 'Posição',
										},
										{
											title: 'Pontos',
										},
										{
											title: 'Nick',
										},
										{
											title: '',
										},
									]}
									tbody={scoreboard.sort(
										(a, b) =>
											Number(a.position) -
											Number(b.position)
									)}
									tournamentStatus={
										data?.content.tournament
											?.tournamentStatus
									}
									navigation={{
										data,
										scoreboard,
										page,
										hasMore,
									}}
								/>

								{hasMore && (
									<Button
										isLoading={isLoading}
										onClick={handleLoadMore}
										className="w-full px-3.5 py-3.5 mb-6"
									>
										Carregar mais
									</Button>
								)}
							</>
						)}
					</div>
				)}
			</div>
		</Layout>
	);
};
