import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale/pt-BR';
import { RiArrowLeftSLine } from '@remixicon/react';
import Footer from '../../../components/Footer';
import { Layout } from '../../../components/layout';
import { useBalanceStore } from '../../../store/balance';
import { Withdraw2 } from '../../../components/Icons/Withdraw2';
import { Deposit2 } from '../../../components/Icons/Deposit2';
import { Transaction } from '../../../components/Icons/Transaction';
import { useApi } from '../../../hooks/useApi';
import { useTokenStore } from '../../../store/token';
import Toast from '../../../components/ui/toast';
import {
	CurrencyFormatter,
	sentryCapture,
	showWalletAndBalance,
} from '../../../helpers/utils';
import { FinanceOperationDisplayType, SentryError } from '../../../types/enums';
import Button from '../../../components/ui/button';

import walletImage from '../../../assets/male-soccer-player-with-ball-grass-field.png';
import { FutProIcon } from '../../../components/Icons/FutProIcon';

export const Wallet = () => {
	const api = useApi();
	const token = useTokenStore((state: any) => state.token);
	const currentBalance = useBalanceStore((state) => state.balance);
	const currentBalanceReal = useBalanceStore((state) => state.balanceReal);
	const currentBalanceBonus = useBalanceStore((state) => state.balanceBonus);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [transactions, setTransactions] = useState<any[]>([]);
	const [page, setPage] = useState<number>(0);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [hasMore, setHasMore] = useState(true);
	const pageSize = 2;

	useEffect(() => {
		if (isLoading) return;

		fetchTransactions(page);
	}, [page]);

	const fetchTransactions = async (page: number) => {
		setIsLoading(true);
		try {
			if (totalPages !== 0) {
				if (page >= totalPages) {
					setHasMore(false);
					return;
				}
			}

			const response = await api.fetchTransactions(token, page, pageSize);
			if (!response?.success) {
				throw new Error(response.message);
			}
			setTotalPages(response.totalPages);
			setTransactions((prevTransactions: any) => [
				...prevTransactions,
				...response.data,
			]);
			if (page >= response.totalPages) {
				setHasMore(false);
				return;
			}
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Layout>
			<div className="items-center flex-row flex justify-between py-6 px-3.5 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
				<a
					onClick={() => {
						navigate('/profile');
					}}
					className="lg:hidden cursor-pointer"
				>
					<RiArrowLeftSLine />
				</a>
				<p className="text-xl font-semibold text-custom-gray1 font-bahnschrift">
					Minha Carteira
				</p>
				<div></div>
			</div>

			<div className="flex flex-col w-full px-3 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
				<div
					className="relative w-full h-40 bg-cover rounded-lg shadow bg-custom-black"
					style={{
						backgroundImage: `url(${walletImage})`,
						mixBlendMode: 'darken',
					}}
				>
					<div className="absolute inset-0 flex flex-col items-center justify-center bg-custom-black bg-opacity-80 rounded-lg lg:items-start lg:pl-16">
						<p className="text-base text-custom-white text-lef lg:text-xl lg:text-primary font-bahnschrift">
							Saldo
						</p>
						<p className="text-4xl font-bold text-custom-white text-left font-bahnschrift flex gap-1 items-center">
							{showWalletAndBalance() ? (
								'R$ '
							) : (
								<FutProIcon style="w-7 h-7" color="#ffffff" />
							)}
							{CurrencyFormatter(Number(currentBalance))}
						</p>
						<div className="flex flex-row gap-3 pt-1">
							<div className="text-custom-white text-left text-xs font-bahnschrift">
								Disponivel para saque
								<p className="text-sm font-bold text-custom-white text-left font-bahnschrift flex gap-1 items-center">
									{showWalletAndBalance() ? (
										'R$ '
									) : (
										<FutProIcon
											style="w-7 h-7"
											color="#ffffff"
										/>
									)}
									{CurrencyFormatter(
										Number(currentBalanceReal)
									)}
								</p>
							</div>
							<div className="text-custom-white text-left text-xs font-bahnschrift">
								Bônus
								<p className="text-sm font-bold text-custom-white text-left font-bahnschrift flex gap-1 items-center">
									{showWalletAndBalance() ? (
										'R$ '
									) : (
										<FutProIcon
											style="w-7 h-7"
											color="#ffffff"
										/>
									)}
									{CurrencyFormatter(
										Number(currentBalanceBonus)
									)}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-row gap-2 items-center justify-center relative bottom-7 lg:bottom-28 lg:right-16 lg:justify-end lg:gap-6">
					<button
						onClick={() => navigate('/wallet/deposit')}
						className="w-[67px] h-[60px] py-2.5 px-5 gap-1 flex flex-col items-center justify-center rounded-lg bg-custom-white shadow"
					>
						<Deposit2 />
						<p className="text-xs text-center text-custom-gray3 font-bahnschrift">
							Depositar
						</p>
					</button>
					<button
						onClick={() => navigate('/wallet/withdraw')}
						className="w-[67px] h-[60px] py-2.5 px-5 gap-1 flex flex-col items-center justify-center rounded-lg bg-custom-white shadow-md"
					>
						<Withdraw2 />
						<p className="text-xs text-center text-custom-gray3 font-bahnschrift">
							Sacar
						</p>
					</button>
				</div>
				<div className="flex flex-col py-5 gap-3.5">
					<p className="text-xl font-semibold text-left text-custom-gray3 font-bahnschrift hidden lg:block">
						Transações
					</p>

					{transactions.map((transaction: any, index: number) => (
						<div
							key={index}
							className="flex flex-row py-3 px-5 items-center justify-between rounded-lg bg-custom-gray7"
						>
							<Transaction classname="mr-3.5" />
							<div className="w-full flex flex-row items-center justify-between gap-1">
								<div className="w-2/5">
									<p
										className={
											'text-sm text-left text-custom-gray1 font-bahnschrift ' +
											(transaction.isCanceled
												? 'line-through'
												: '')
										}
									>
										{transaction.historic}
									</p>
									<p className="text-xs text-left text-custom-gray4 font-bahnschrift">
										{format(
											transaction.transcationAt,
											"dd 'de' MMMM, HH:mm",
											{ locale: ptBR }
										)}
									</p>
								</div>
								<div
									className={
										'w-1/3 text-sm text-right font-bahnschrift flex justify-end items-center gap-1 ' +
										(transaction.isDebit
											? 'text-primary '
											: 'text-[#2CDA67] ') +
										(transaction.isCanceled
											? 'line-through'
											: '')
									}
								>
									{showWalletAndBalance() ? (
										'R$ '
									) : (
										<FutProIcon
											style="w-3.5 h-3.5"
											color={
												transaction.isDebit
													? '#DF1737'
													: '#2CDA67'
											}
										/>
									)}
									{CurrencyFormatter(
										Number(transaction.transcationValue)
									)}
								</div>
							</div>
						</div>
					))}
					{hasMore && (
						<Button
							onClick={() => {
								setPage(page + 1);
							}}
							isLoading={isLoading}
							className="py-4"
						>
							Carregar mais
						</Button>
					)}
				</div>
			</div>

			<Footer />
		</Layout>
	);
};
